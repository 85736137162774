import React from "react";
import Image from "next/image";
import Link from "next/link";

const HeroSection: React.FC = () => {
  return (
    <section
      role="banner"
      className="relative w-full h-screen flex items-center justify-center text-center text-white px-4 sm:px-8 lg:px-24"
    >
      {/* Background Image Optimization */}
      <div className="absolute inset-0 z-0">
        <Image
          src="/hero-bg.webp"
          alt="Professional Counselling Services in Calgary"
          fill
          style={{ objectFit: "cover" }}
          quality={75}
          priority
          loading="eager"
          decoding="async"
        />
      </div>

      {/* Gradient Overlay (Lighter for Better LCP) */}
      <div className="absolute inset-0 bg-gradient-to-t from-gray-900/80 to-gray-900/60 z-1"></div>

      {/* Main Content */}
      <div className="relative z-10 max-w-4xl">
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold leading-tight mb-6 tracking-tight drop-shadow-lg">
          Calgary&apos;s Premier Psychologist & Counselling Services
        </h1>

        <p className="text-lg md:text-xl font-light mb-6 max-w-3xl mx-auto leading-relaxed">
          Welcome to <strong className="text-emerald-200">Alberta Counselling Services</strong>, offering expert
          <strong className="text-emerald-200"> Relationship Counselling</strong>,
          <strong> Marriage Counselling</strong>, and <strong>Family Counselling in Calgary</strong>. Our
          <strong className="text-emerald-200"> Registered Psychologist</strong> brings over 35 years of experience to help you thrive.
        </p>

        {/* CTA Button - Now With Faster Load Times */}
        <Link
          href="/contact"
          className="inline-flex items-center bg-emerald-700 hover:bg-emerald-800 text-white text-lg font-bold py-4 px-12 rounded-lg shadow-xl transition-all duration-300 ease-out border-2 border-transparent hover:border-white hover:scale-105"
          aria-label="Contact us to start your journey with Calgary's Best Psychologist"
        >
          Start Your Journey Today
        </Link>
      </div>
    </section>
  );
};

export default HeroSection;
